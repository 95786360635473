// extracted by mini-css-extract-plugin
export var btn = "Solutions-module--btn--e575f";
export var cards = "Solutions-module--cards--3f77f";
export var description = "Solutions-module--description--a063b";
export var hire = "Solutions-module--hire--86235";
export var hireCyber = "Solutions-module--hireCyber--a4f4d";
export var hireImg = "Solutions-module--hireImg--8b8bf";
export var hireImgBlock = "Solutions-module--hireImgBlock--8f3eb";
export var talent = "Solutions-module--talent--9b5ca";
export var teamBtn = "Solutions-module--teamBtn--9586f";
export var text = "Solutions-module--text--ac319";
export var tick = "Solutions-module--tick--1b4b7";