// extracted by mini-css-extract-plugin
export var cardBg = "Technologies-module--cardBg--6ae7c";
export var cards = "Technologies-module--cards--73dd3";
export var cardsContent = "Technologies-module--cardsContent--8c51a";
export var description = "Technologies-module--description--ca58d";
export var globalIndus = "Technologies-module--globalIndus--a14d3";
export var heading = "Technologies-module--heading--5cba2";
export var iconssCard = "Technologies-module--iconssCard--1efe5";
export var nav = "Technologies-module--nav--8ef68";
export var navItem = "Technologies-module--nav-item--383ca";
export var navbarBlock = "Technologies-module--navbarBlock--7ace7";
export var tabData = "Technologies-module--tabData--c44ae";
export var tech = "Technologies-module--tech--44757";
export var techIcon = "Technologies-module--techIcon--00ad0";
export var techImg = "Technologies-module--techImg--fb0ff";
export var technologyIcon = "Technologies-module--technologyIcon--5790b";