import React from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import * as styles from "./Benefits.module.scss"

const Benefits = ({ strapiData }) => {
  return (
    <Container fluid className={`${styles.mainContainer}`}>
      <Row className={`${styles.mainRow}`}>
        <Container>
          <Row className={`${styles.innerRow}`}>
            <Col lg={4} md={12} sm={12} xs={12}>
              <div className={styles.sticky}>
                <img
                  decoding="async"
                  loading="lazy"
                  src={strapiData?.secImages[0]?.localFile?.publicURL}
                  alt="FeaturedImg"
                />
                <h2
                  className={`${styles.benefitsHead}`}
                  dangerouslySetInnerHTML={{ __html: strapiData?.title }}
                />
                <p
                  className={`${styles.mainDesc}`}
                  dangerouslySetInnerHTML={{
                    __html: strapiData?.subTitle,
                  }}
                />
              </div>
            </Col>
            <Col lg={8} md={12} sm={12} xs={12}>
              <Row className={`gap-30 ${styles.cardWrapper}`}>
                {strapiData?.cards?.map((e, i) => (
                  <Col xl={6} md={6} xs={12} key={i} className="gap-30 d-flex">
                    <div className={styles.card}>
                      <img
                        src={e?.image1[0]?.localFile?.publicURL}
                        decoding="async"
                        loading="lazy"
                        alt={e?.title}
                      />
                      <h3>{e?.title}</h3>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: e?.subTitle,
                        }}
                      />
                    </div>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </Container>
      </Row>
    </Container>
  )
}

export default Benefits
