// extracted by mini-css-extract-plugin
export var btn = "Process-module--btn--fb559";
export var card = "Process-module--card--b6797";
export var cardWrapper = "Process-module--cardWrapper--4c266";
export var description = "Process-module--description--353b0";
export var hire = "Process-module--hire--d03cc";
export var hireCyber = "Process-module--hireCyber--8caac";
export var leftCol = "Process-module--leftCol--751c0";
export var processTitle = "Process-module--processTitle--eaa26";
export var processteamBtn = "Process-module--processteamBtn--e02b1";
export var start = "Process-module--start--36a01";