import React, { useState } from "react"
import Accordion from "react-bootstrap/Accordion"
import Container from "react-bootstrap/Container"
import Card from "react-bootstrap/Card"
import "./accordian.scss"
import * as styles from "./Technologies.module.scss"

const AccordianTech = ({ strapiData }) => {
  const data = [
    {
      title: "Public & Private Clouds",
      tech: [
        {
          name: "AWS",
          icon: "https://invozone-backend.s3.amazonaws.com/aws_945808ba35.svg",
        },
        {
          name: "Azure",
          icon: "https://invozone-backend.s3.amazonaws.com/azure_7686867c9d.svg",
        },
        {
          name: "Google Cloud",
          icon: "https://invozone-backend.s3.amazonaws.com/google_cloud_platform_b522826248.svg",
        },
        {
          name: "Digital Ocean",
          icon: "https://invozone-backend.s3.amazonaws.com/digital_ocean_085e65e983.svg",
        },
        {
          name: "DC/OS",
          icon: "https://invozone-backend.s3.amazonaws.com/dc_os_e3318ee0bc.svg",
        },
      ],
    },
    {
      title: "Infrastructure as a Code",
      tech: [
        {
          name: "HELM",
          icon: "https://invozone-backend.s3.amazonaws.com/helm_d6e68d4494.svg",
        },
        {
          name: "Terraform",
          icon: "https://invozone-backend.s3.amazonaws.com/terraform_1c57c594b2.svg",
        },
        {
          name: "Azure Manager",
          icon: "https://invozone-backend.s3.amazonaws.com/azure_resource_manager_5e1d2e5722.svg",
        },
        {
          name: "Power Shell",
          icon: "https://invozone-backend.s3.amazonaws.com/power_shell_70accd1084.svg",
        },
        {
          name: "AWS Cloudfromation",
          icon: "https://invozone-backend.s3.amazonaws.com/aws_cloudfromation_6d85a8bffd.svg",
        },
      ],
    },
  ]

  const [activeAccordion, setActiveAccordion] = useState(`${data[0]?.title}`)
  // const [activeAccordionIndex, setActiveAccordionIndex] = useState(0)
  return (
    <div className="tech">
      <div className={styles.globalIndus}>
        <Container>
          <h2
            className={styles.heading}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          ></h2>
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: strapiData?.description?.description,
            }}
          />
          <Accordion defaultActiveKey={`${data[0]?.title}`}>
            {data &&
              data?.map((e, i) => (
                <Card className="" key={i}>
                  <Accordion.Toggle
                    as={Card.Header}
                    eventKey={`${e?.title}`}
                    className={`eduAccord ${
                      e?.title === activeAccordion ? "active-accordion" : ""
                    } ${
                      i === 1 && e?.title !== activeAccordion
                        ? "second-accordion"
                        : i === 0 && e?.title !== activeAccordion
                        ? "first-accordion"
                        : ""
                    }`}
                    onClick={() => setActiveAccordion(e?.title)}
                    style={
                      i === 1 && e?.title === activeAccordion
                        ? { marginTop: "24px" }
                        : {}
                    }
                  >
                    {e?.title}
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey={`${e?.title}`}>
                    <Card.Body className={`cardBody`}>
                      <div className={styles.techIcon}>
                        {e?.tech &&
                          e?.tech?.map((el, i) => (
                            <div className={`${styles.iconssCard}`} key={i}>
                              <div className={styles.technologyIcon}>
                                <div className={styles.techImg}>
                                  <img
                                    decoding="async"
                                    loading="lazy"
                                    src={el?.icon}
                                    alt={el?.name}
                                  />
                                </div>
                                <h3>{el?.name}</h3>
                              </div>
                            </div>
                          ))}
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              ))}
          </Accordion>
        </Container>
      </div>
    </div>
  )
}

export default AccordianTech
