// extracted by mini-css-extract-plugin
export var bottomDes = "HireDevelopers-module--bottomDes--193e3";
export var btn = "HireDevelopers-module--btn--cf989";
export var cards = "HireDevelopers-module--cards--80fe2";
export var description = "HireDevelopers-module--description--9c146";
export var hire = "HireDevelopers-module--hire--e268a";
export var hireCyberDeveloper = "HireDevelopers-module--hireCyberDeveloper--ebacb";
export var hireImg = "HireDevelopers-module--hireImg--5c961";
export var subTitle = "HireDevelopers-module--subTitle--1d9b2";
export var talent = "HireDevelopers-module--talent--389f4";
export var teamBtn = "HireDevelopers-module--teamBtn--f7ffe";
export var text = "HireDevelopers-module--text--fdb6b";
export var tick = "HireDevelopers-module--tick--f298d";