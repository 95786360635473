import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import educationBanner from "../../images/educational-system/education.png"
import bannerImgMobile from "../../images/educational-system/bannerImgMobile.png"
import * as styles from "./Banner.module.scss"

const Banner = ({ strapiData }) => {
  const image = getImage(strapiData?.secImages[0]?.localFile)

  return (
    <div className={styles.banner}>
      <Container>
        <Row className="align-items-center">
          <Col lg={6} md={12}>
            <div className={styles.heading}>
              <h1
                className={styles.bannerHeading}
                dangerouslySetInnerHTML={{ __html: strapiData?.title }}
              />
              <p
                dangerouslySetInnerHTML={{
                  __html: strapiData?.description?.description,
                }}
              />
            </div>
            <div className={`${styles.bannerBtn}`}>
              {strapiData?.buttons[0] && (
                <Link
                  to={strapiData?.buttons[0]?.url}
                  className={styles.btn_white2_border}
                >
                  {strapiData?.buttons[0]?.title}
                </Link>
              )}
            </div>
          </Col>
          <Col lg={6} md={12} className={styles.ColLeft}>
            <div className={styles.imgBlock}>
              {image ? (
                <GatsbyImage
                  placeholder="blurred"
                  decoding="async"
                  loading="lazy"
                  image={image}
                  alt={strapiData?.title}
                />
              ) : (
                <img
                  alt="education"
                  src={educationBanner}
                  decoding="async"
                  loading="lazy"
                  className={`${styles.BannerImage}`}
                />
              )}
              <img
                src={bannerImgMobile}
                placeholder="blurred"
                decoding="async"
                loading="lazy"
                className={styles.mobImg}
                alt="icon"
              />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Banner
