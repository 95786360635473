import React, { useState } from "react"
import Nav from "react-bootstrap/Nav"
import Container from "react-bootstrap/Container"
import Tab from "react-bootstrap/Tab"
import "./tabbing.scss"
import * as styles from "./Technologies.module.scss"

const data = [
  {
    title: "Technologies",
    tech: [
      {
        name: "Flutter",
        icon: "https://invozone-backend.s3.amazonaws.com/flutter_3f6aaeac5b.svg",
      },
      {
        name: "Python",
        icon: "https://invozone-backend.s3.amazonaws.com/python_5577e9dbec.svg",
      },
      {
        name: ".NET ",
        icon: "https://invozone-backend.s3.amazonaws.com/dot_net_46d3788eb7.svg",
      },
      {
        name: "Node. JS",
        icon: "https://invozone-backend.s3.amazonaws.com/node_js_e54f57c69a.svg",
      },
      {
        name: "React JS",
        icon: "https://invozone-backend.s3.amazonaws.com/javascript_45768a30bf.svg",
      },
      {
        name: "Java Script",
        icon: "https://invozone-backend.s3.amazonaws.com/react_native_3246a794ae.svg",
      },
      {
        name: "Java",
        icon: "https://invozone-backend.s3.amazonaws.com/java_8a62c3c5fc.svg",
      },
      {
        name: "PHP",
        icon: "https://invozone-backend.s3.amazonaws.com/php_5de0c8576e.svg",
      },
      {
        name: "Kotlin",
        icon: "https://invozone-backend.s3.amazonaws.com/kotlin_5a9b647ff1.svg",
      },
      {
        name: "Swift",
        icon: "https://invozone-backend.s3.amazonaws.com/swift_98d5b3fd47.svg",
      },
      {
        name: "Vue.js",
        icon: "https://invozone-backend.s3.amazonaws.com/vue_js_80bdf6b526.svg",
      },
      {
        name: "Moodle",
        icon: "https://invozone-backend.s3.amazonaws.com/moodle_9a0ad30ec6.svg",
      },
      {
        name: "Elixir",
        icon: "https://invozone-backend.s3.amazonaws.com/elixir_5d92031ca7.svg",
      },
      {
        name: "Nest.js",
        icon: "https://invozone-backend.s3.amazonaws.com/nest_js_0d8071c02c.svg",
      },
      {
        name: "Next.js ",
        icon: "https://invozone-backend.s3.amazonaws.com/next_js_fda28aef42.svg",
      },
    ],
  },
  {
    title: "AWS Tools",
    tech: [
      {
        name: "S3",
        icon: "https://invozone-backend.s3.amazonaws.com/aws_s3_88b3c8b3c5.svg",
      },
      {
        name: "RDS",
        icon: "https://invozone-backend.s3.amazonaws.com/aws_rds_ee07e7564f.svg",
      },
      {
        name: "Aurora",
        icon: "https://invozone-backend.s3.amazonaws.com/aws_aurora_0eca006a4a.svg",
      },
      {
        name: "Lambda",
        icon: "https://invozone-backend.s3.amazonaws.com/aws_lambda_3ce7305243.svg",
      },
      {
        name: "Neptune",
        icon: "https://invozone-backend.s3.amazonaws.com/awss_neptune_b22424f90d.svg",
      },
      {
        name: "Cognito",
        icon: "https://invozone-backend.s3.amazonaws.com/aws_cognito_00847f4d5e.svg",
      },
      {
        name: "AppSync",
        icon: "https://invozone-backend.s3.amazonaws.com/aws_appsync_2b137896f5.svg",
      },
      {
        name: "SQS",
        icon: "https://invozone-backend.s3.amazonaws.com/aws_sqs_e0479eb5b9.svg",
      },
      {
        name: "SNS",
        icon: "https://invozone-backend.s3.amazonaws.com/aws_sns_a9f6f7f861.svg",
      },
      {
        name: "CloudFront",
        icon: "https://invozone-backend.s3.amazonaws.com/aws_cloudfront_974e41621f.svg",
      },
    ],
  },
]

const Technologies = ({ strapiData }) => {
  const [activeTab, setActiveTab] = useState(data[0]?.title)

  const handleTabClick = label => {
    setActiveTab(label)
  }

  return (
    <div className="educationTab">
      <div className={styles.globalIndus}>
        <Container>
          <h2
            className={styles.heading}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          ></h2>
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: strapiData?.description?.description,
            }}
          />
          <Tab.Container
            id="left-tabs-example"
            defaultActiveKey={`${data[0]?.title}`}
          >
            <div className={styles.cardsContent}>
              <div>
                <Nav variant="pills" className={`eduTab`}>
                  {data &&
                    data?.map((e, i) => (
                      <Nav.Item key={i} className="cards">
                        <div
                          eventKey={`${e?.title}`}
                          className={`tabLink ${
                            activeTab === e.title ? "active" : ""
                          }`}
                          onClick={() => handleTabClick(e.title)}
                        >
                          <div className={styles.tabData}>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: e?.title,
                              }}
                            />
                          </div>
                        </div>
                      </Nav.Item>
                    ))}
                </Nav>
              </div>
              <Tab.Content className="contentBlock">
                {data &&
                  data?.map((e, i) => (
                    <div
                      key={i}
                      className={`tab-pane ${
                        activeTab === e.title ? "active" : ""
                      }`}
                    >
                      <div className={styles.techIcon}>
                        {e?.tech &&
                          e?.tech?.map((el, i) => (
                            <div className={`${styles.iconssCard}`} key={i}>
                              <div className={styles.technologyIcon}>
                                <div className={styles.techImg}>
                                  <img
                                    decoding="async"
                                    loading="lazy"
                                    src={el?.icon}
                                    alt={el?.name}
                                  />
                                </div>
                                <h3>{el?.name}</h3>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  ))}
              </Tab.Content>
            </div>
          </Tab.Container>
        </Container>
      </div>
    </div>
  )
}
export default Technologies
