import React, { useRef, useEffect, useState } from "react"
import Container from "react-bootstrap/Container"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import AOS from "aos"
import "aos/dist/aos.css"
import * as styles from "./Process.module.scss"
import { Link } from "gatsby"

const Process = ({ strapiData }) => {
  const image = getImage(strapiData?.secImages[0]?.localFile)

  const cardRefs = strapiData?.cards?.map(() => useRef())
  const [zIndexValues, setZIndexValues] = useState([])

  useEffect(() => {
    AOS.init({
      disable: function () {
        var maxWidth = 991
        return window.innerWidth < maxWidth
      },
    })
  }, [])

  return (
    <div className={styles.hireCyber}>
      <Container>
        <div>
          <Row className={styles.start}>
            <Col
              lg={6}
              md={12}
              xs={12}
              sm={12}
              className={`text-center ${styles.leftCol}`}
            >
              <div>
                {image ? (
                  <GatsbyImage
                    placeholder="blurred"
                    decoding="async"
                    loading="lazy"
                    image={image}
                    alt={strapiData?.title}
                  />
                ) : (
                  <img
                    src={strapiData?.secImages[0]?.localFile?.publicURL}
                    alt={strapiData?.title}
                    decoding="async"
                    loading="lazy"
                  />
                )}
              </div>
              {/* <img src={developmentProcess} alt="" />  */}
              <div className={styles.hire}>
                <h2
                  className={`${styles.processTitle}`}
                  dangerouslySetInnerHTML={{ __html: strapiData?.title }}
                />
                <div
                  className={styles.description}
                  dangerouslySetInnerHTML={{
                    __html: strapiData?.description?.description,
                  }}
                />
                <p
                  className={`${styles.description}`}
                  dangerouslySetInnerHTML={{
                    __html: strapiData?.subTitle,
                  }}
                />
                <div className={styles.processteamBtn}>
                  {strapiData?.buttons[0] && (
                    <Link
                      to={strapiData?.buttons[0]?.url}
                      className={styles.btn}
                    >
                      {strapiData?.buttons[0]?.title}
                    </Link>
                  )}
                </div>
              </div>
            </Col>
            <Col
              lg={6}
              md={12}
              xs={12}
              sm={12}
              className={`${styles.cardWrapper}`}
            >
              <Row className={`justify-content-center`}>
                {strapiData?.cards?.slice(0, 5).map((e, i) => (
                  <Col
                    lg={12}
                    md={6}
                    data-aos="fade-left"
                    data-aos-delay={
                      i === 1
                        ? "700"
                        : i === 2
                        ? "500"
                        : i === 3
                        ? "400"
                        : i === 4
                        ? "200"
                        : i === 5
                        ? "100"
                        : "200"
                    }
                    data-aos-duration={
                      i === 1
                        ? "2000"
                        : i === 2
                        ? "3000"
                        : i === 3
                        ? "2000"
                        : i === 4
                        ? "2000"
                        : i === 5
                        ? "1000"
                        : "5000"
                    }
                    key={i}
                    className={`    ${styles.card}`}
                    ref={cardRefs[i]}
                    style={{ zIndex: zIndexValues[i] }}
                  >
                    <div>
                      <img
                        src={e?.image1 && e?.image1[0]?.localFile?.publicURL}
                        alt={e?.title}
                        decoding="async"
                        loading="lazy"
                      />
                      <h3>{e?.title}</h3>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: e?.subTitle,
                        }}
                      />
                    </div>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  )
}

export default Process
