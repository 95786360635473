// extracted by mini-css-extract-plugin
export var btn = "LatestFeatures-module--btn--092db";
export var cards = "LatestFeatures-module--cards--a4239";
export var description = "LatestFeatures-module--description--deda7";
export var hire = "LatestFeatures-module--hire--f97d4";
export var hireCyber = "LatestFeatures-module--hireCyber--2fd84";
export var hireImg = "LatestFeatures-module--hireImg--873bf";
export var subTitle = "LatestFeatures-module--subTitle--5acdf";
export var talent = "LatestFeatures-module--talent--593cf";
export var teamBtn = "LatestFeatures-module--teamBtn--0191b";
export var text = "LatestFeatures-module--text--e3510";
export var tick = "LatestFeatures-module--tick--a33c0";